import { Component, OnInit, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';

/** AP Interface */
import { APs } from '../merakiAps';
/** Meraki Service  */
import { MerakiApiService } from '../meraki-api.service';

@Component({
  selector: 'ap-detail',
  templateUrl: './ap-detail.component.html',
  styleUrls: ['./ap-detail.component.css'],
})
export class ApDetailComponent {
  radioSerial: string = this.route.snapshot.paramMap.get('serial');
  apData: APs;
  isDataAvailable: boolean = false;
  ModelOnOff: boolean;
  lat: number;
  lng: number;
  title: string = '';

  constructor(private router: Router, private route: ActivatedRoute, private merakiapi: MerakiApiService) {
    merakiapi.getAPDetail(this.radioSerial).subscribe(data => {
      this.apData = data;
      this.isDataAvailable = true;
      this.ModelOnOff = this.apData.tags.match(/radio_off/g) ? false : true;
      /** Set the title according the value from api */
      this.title = this.switchTitle();
    });
  }
/** Switch the Title  */
  switchTitle() {
    return this.ModelOnOff ? 'Wireless ON' : 'Wireless OFF';
  }

  switchAP(event: any) {
    /** Switch Tag to on/off  */
    const tag = this.ModelOnOff ? 'on' : 'off';

    this.merakiapi.setApMode(this.radioSerial, tag).subscribe(data => {
      this.apData = data;
      console.log(data);
      this.title = this.switchTitle();
    });
  }
}
