import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { NotFoundComponentComponent } from './not-found-component/not-found-component.component';
import { ApDetailComponent } from './ap-detail/ap-detail.component';
import { ApListComponent } from './aplist/aplist.component';
import { CallbackComponent } from './pages/callback/callback.component';
import { GuestaccessComponent } from './guestaccess/guestaccess.component';
import { StatsComponent } from './stats/stats.component';

import { AuthGuard } from './auth/auth.guard';
import { AdminGuard } from './auth/admin.guard';

const routes: Routes = [
  { path: '', component: CallbackComponent, pathMatch: 'full' },
  { path: 'callback', component: CallbackComponent, pathMatch: 'full'},
  { path: 'callback#', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard]},

  { path: 'ap/:serial', component: ApDetailComponent, canActivate: [AuthGuard]},
  { path: 'aplist', component: ApListComponent, canActivate: [AuthGuard] },
  { path: 'guest-access', component: GuestaccessComponent, canActivate: [AuthGuard]},
  { path: 'stats', component: StatsComponent, canActivate: [AuthGuard]},
  { path: '**', component: NotFoundComponentComponent, canActivate: [AuthGuard]}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard, AdminGuard]
})
export class AppRoutingModule {}
