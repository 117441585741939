import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { APs } from './merakiAps';
import { NET } from './network.interface';
import { AuthService } from './auth/auth.service';
import { throwError as ObservableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MerakiApiService {

  now = 'https://us-central1-mrkilab.cloudfunctions.net/webApi/v1';


  constructor(private http: HttpClient,  private auth: AuthService) {}

  private get _authHeader(): string {
    return `Bearer ${this.auth.accessToken}`;
  }



  getDevicelist() {
    console.log(this.now);
    return this.http.get<APs>(`${this.now}/devicelist`, {
      headers: new HttpHeaders().set('Authorization', this._authHeader) })
      .pipe (
        catchError((error) => this._handleError(error))
      );
  }
  getDevicelistByNetwork(network) {
    console.log(this.now);
    return this.http.get<APs>(`${this.now}/devicelist/${network}`, {
      headers: new HttpHeaders().set('Authorization', this._authHeader) })
      .pipe (
        catchError((error) => this._handleError(error))
      );
  }
  get1kAps() {
    return this.http.get<APs>(`${this.now}/devicelist`, {
      headers: new HttpHeaders().set('Authorization', this._authHeader) })
      .pipe (
        catchError((error) => this._handleError(error))
      );
  }
  getAPDetail(deviceSerial: string) {
    console.log(deviceSerial);
    return this.http.get<APs>(`${this.now}/apdetails/${deviceSerial}`, {
      headers: new HttpHeaders().set('Authorization', this._authHeader) })
      .pipe (
        catchError((error) => this._handleError(error))
      );
  }
  setApMode(deviceSerial: string, apMode: string) {

    return this.http.get<APs>(`${this.now}/updatedevice/${deviceSerial}/radio_${apMode}`, {
      headers: new HttpHeaders().set('Authorization', this._authHeader) })
      .pipe(
      catchError((error) => this._handleError(error))
    );

  }
  getNetworkList() {
    return this.http.get<NET>(`${this.now}/networklist`, {
      headers: new HttpHeaders().set('Authorization', this._authHeader) })
      .pipe(
        catchError((error) => this._handleError(error))
      );
    }
  private _handleError(err: HttpErrorResponse | any): Observable<any> {
    const errorMsg = err.message || 'Error: Unable to complete request.';
    if (err.message && err.message.indexOf('No JWT present') > -1) {
      this.auth.login();
    }
    return ObservableThrowError(errorMsg);
  }


}
