import { ENV } from './../core/env.config';

interface AuthConfig {
  CLIENT_ID: string;
  CLIENT_DOMAIN: string;
  AUDIENCE: string;
  REDIRECT: string;
  SCOPE: string;
  NAMESPACE: string;
};

export const AUTH_CONFIG: AuthConfig = {
  CLIENT_ID: 'TGpqXEqdqe2iZLfraLzzQhTETjQUWGF8',
  CLIENT_DOMAIN: 'auth.wifisight.school', // e.g., you.auth0.com
  AUDIENCE: 'http://mrkilab.meraki',
  //REDIRECT: 'http://localhost:4200/callback',
  REDIRECT: 'https://wifisight.school/callback',
  SCOPE: 'openid profile',
  NAMESPACE: 'http://myapp.com/roles'
};
